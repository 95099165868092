<template>
  <div class="container-xl">
    <div class="card h-full">
      <div class="card-table table-responsive align-items-center" align="center">
        <img width="200" :src="ic_error_user"/>
        <h3>Oops</h3>
        <p>
          Forbidden. You do not have access to this page :)
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import ic_error_user from '@/assets/images/ic_error_user.png';

export default {
  name: 'Forbidden',
  metaInfo: {
    title: 'Forbidden',
  },
  data() {
    return {
      ic_error_user,
    };
  },
};
</script>
